/* Landing pAGE */
.landing{
  height: 800px;
}
.slide1{
  background-image: url("https://www.thespruce.com/thmb/vqK7yBSqliR43hlRhK3Tg1YJBG0=/2121x1414/filters:fill(auto,1)/GettyImages-994966932-72421de8a3844d6db50ba9507aec83e2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.slide2{
  background-image: url("https://guesthouseonthemount.ca/wp-content/uploads/2017/01/Rooms-Rates-02.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.slide3{
  background-image: url("https://cf.bstatic.com/xdata/images/hotel/max1024x768/185650309.jpg?k=64def6690a26ead9bc5b38601e8d781b43bee66dba218e991498ffdfa604615a&o=&hp=1");
  background-repeat: no-repeat;
  background-size: cover;
}
.main-text{
  font-size: 65px;
}
.sec-text{
  font-size : 20px;
}
.learn-more{
  border-radius: 25px;
}

.aside{
  background: url("https://mydecorative.com/wp-content/uploads/2013/06/Luxurious-hotel-room.jpg") center ;
  background-size: cover;


  /* Rectangle 8 */
}
.holder{
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.holder > .row > .col-md-6 > h1{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  color: #FFFFFF;
}
.feat{
  margin: 40px;
}
.feat > p {
  padding-top: 40px;
}
.item {
  height: 500px;
}
@media (max-width : 540px) {
  .overlay{
    padding: 0 20px;
  }
  .box{
    padding:20px;
  }
  .feat{
    margin : 0
  }
  .feat > p {
    padding-top: 0;
  }
  .feat > div {
    margin: 0;
  }
  .item {
    height: 400px;
    padding: 20px 0;
  }
}